import React from 'react';
import appDevImage from '../images/App Dev.webp';
import '../styles/Section.css';

const MobileAppDevelopment = () => {
  return (
    <section className="section hero-section">
      <img src={appDevImage} alt="App Development" className="hero-image" />
      <div className="section-content">
        <h2>การพัฒนาแอปพลิเคชันมือถือ</h2>
        <h3>แอปมือถือแบบเนทีฟ</h3>
        <p>
          เรามีความเชี่ยวชาญในการพัฒนาแอปมือถือแบบเนทีฟที่ให้ประสิทธิภาพและประสบการณ์ผู้ใช้ที่เหนือกว่า บริการพัฒนาแอปมือถือแบบเนทีฟของเราครอบคลุมทั้งแพลตฟอร์ม iOS และ Android เพื่อให้ผู้ใช้ของคุณมีประสบการณ์ที่ราบรื่น
        </p>
        <h3>แอปมือถือข้ามแพลตฟอร์ม</h3>
        <p>
          สำหรับธุรกิจที่ต้องการเข้าถึงผู้ชมที่กว้างขึ้น บริการพัฒนาแอปมือถือข้ามแพลตฟอร์มของเราเป็นโซลูชันที่คุ้มค่า เราใช้เทคโนโลยีล่าสุดในการสร้างแอปที่ทำงานได้อย่างไม่มีที่ติในหลายแพลตฟอร์ม
        </p>
        <h3>การดูแลและสนับสนุนแอปมือถือ</h3>
        <p>
          Logics Forge ให้บริการดูแลและสนับสนุนแอปมือถืออย่างต่อเนื่องเพื่อให้มั่นใจว่าแอปจะทำงานได้อย่างมีประสิทธิภาพและเป็นปัจจุบัน บริการของเรารวมถึงการแก้ไขข้อบกพร่อง การอัปเดต และการปรับปรุงเพื่อให้แอปของคุณทำงานได้อย่างราบรื่น
        </p>
      </div>
    </section>
  );
};

export default MobileAppDevelopment;
