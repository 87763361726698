import React from 'react';
import '../styles/SplashScreen.css';

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <div className="splash-content">
        <div className="logo-placeholder">
          <img src="path/to/your/logo.png" alt="Logo here" />
        </div>
        <p className="logo-text">LOGICS FORGE</p>
        <p className="splash-text">"Website Application SEO"</p>
      </div>
    </div>
  );
};

export default SplashScreen;
