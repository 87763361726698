import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SplashScreen from './components/SplashScreen';
import Home from './pages/Home';
import About from './pages/About';
import './styles/About.css'; // ตรวจสอบให้แน่ใจว่าเส้นทางถูกต้อง

// ตรวจสอบให้แน่ใจว่าเส้นทางการนำเข้าถูกต้อง
import Header from './components/Header';
import WebDevelopment from './components/WebDevelopment';
import WebDesign from './components/WebDesign';
import MobileAppDevelopment from './components/MobileAppDevelopment';
import OnlineMarketing from './components/OnlineMarketing';
import SEOService from './components/SEOService';
import Benefits from './components/Benefits';
import ServicesSummary from './components/ServicesSummary';
import ContactInfo from './components/ContactInfo';
import FAQ from './components/FAQ';
import './App.css';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000); // เปลี่ยนเป็นระยะเวลาที่ต้องการให้ Splash Screen แสดงผล
  }, []);

  return (
    <Router>
      {loading ? (
        <SplashScreen />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
