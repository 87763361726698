import React from 'react';
import '../styles/Section.css';

const FAQ = () => {
  return (
    <section className="section hero-section">
      <div className="section-content">
        <h2>คำถามที่พบบ่อย</h2>
        <h3>Logics Forge ให้บริการอะไรบ้าง?</h3>
        <p>Logics Forge ให้บริการพัฒนาเว็บไซต์ การออกแบบ การสร้างแอปพลิเคชันมือถือ การตลาดออนไลน์ และบริการ SEO</p>
        <h3>เว็บไซต์มืออาชีพจะเป็นประโยชน์ต่อธุรกิจของฉันอย่างไร?</h3>
        <p>เว็บไซต์มืออาชีพช่วยเพิ่มการแสดงตนในโลกออนไลน์ ดึงดูดลูกค้าเป้าหมาย และเป็นแพลตฟอร์มสำหรับการมีส่วนร่วมกับผู้ชมของคุณ</p>
        <h3>การออกแบบที่ตอบสนองต่อทุกอุปกรณ์มีความสำคัญอย่างไร?</h3>
        <p>การออกแบบที่ตอบสนองต่อทุกอุปกรณ์ช่วยให้เว็บไซต์ของคุณทำงานได้ดีในทุกอุปกรณ์ มอบประสบการณ์การใช้งานที่ราบรื่นและปรับปรุงการเข้าถึง</p>
        <h3>Logics Forge มีวิธีการอย่างไรกับ SEO?</h3>
        <p>บริการ SEO ของเรารวมถึง SEO บนหน้าเว็บ SEO นอกหน้าเว็บ และ SEO ทางเทคนิคเพื่อปรับปรุงการมองเห็นเว็บไซต์ของคุณและอันดับเครื่องมือค้นหา</p>
        <h3>กระบวนการพัฒนาแอปมือถือกับ Logics Forge เป็นอย่างไร?</h3>
        <p>เราเริ่มต้นด้วยการเข้าใจข้อกำหนดของคุณ ตามด้วยการออกแบบ พัฒนา ทดสอบ และดูแลรักษาแอปเพื่อให้มั่นใจว่าแอปของคุณตรงตามความต้องการของคุณ</p>
        <h3>Logics Forge สามารถช่วยการตลาดผ่านโซเชียลมีเดียได้หรือไม่?</h3>
        <p>ได้ เราให้บริการการตลาดผ่านโซเชียลมีเดียเพื่อช่วยให้คุณสร้างการแสดงตนในโลกออนไลน์ที่แข็งแกร่งและมีส่วนร่วมกับผู้ชมของคุณบนแพลตฟอร์มต่างๆ</p>
      </div>
    </section>
  );
};

export default FAQ;
