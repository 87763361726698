import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faInfoCircle, faNewspaper, faEnvelope, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import '../styles/Home.css';

const Home = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="home">
      {isDesktop && <Header />}
      {isDesktop && <Hero />}
      <div className="app-name">LOGICS FORGE</div>
      <div className={`grid-container ${isDesktop ? 'desktop' : 'mobile'}`}>
        <div className="grid-item">
          <Link to="/about">
            <FontAwesomeIcon icon={faInfoCircle} size="3x" />
            <p>About</p>
          </Link>
        </div>
        <div className="grid-item">
          <Link to="/service">
            <FontAwesomeIcon icon={faHandsHelping} size="3x" />
            <p>Service</p>
          </Link>
        </div>
        <div className="grid-item">
          <Link to="/gallery">
            <FontAwesomeIcon icon={faEdit} size="3x" />
            <p>Gallery</p>
          </Link>
        </div>
        <div className="grid-item">
          <Link to="/news">
            <FontAwesomeIcon icon={faNewspaper} size="3x" />
            <p>News</p>
          </Link>
        </div>
        <div className="grid-item">
          <Link to="/contact">
            <FontAwesomeIcon icon={faEnvelope} size="3x" />
            <p>Contact Us</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
