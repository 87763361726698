import React from 'react';
import '../styles/Section.css';

const Benefits = () => {
  return (
    <section className="section content-section">
      <div className="section-content">
        <h2>ประโยชน์ของการเลือก Logics Forge</h2>
        <h3>ทีมงานที่มีประสบการณ์</h3>
        <p>
          ทีมงานของเราที่ Logics Forge ประกอบด้วยผู้เชี่ยวชาญที่มีประสบการณ์ซึ่งเป็นผู้เชี่ยวชาญในสาขาของตนเอง ตั้งแต่นักพัฒนาไปจนถึงนักออกแบบและนักการตลาด เราทำงานร่วมกันเพื่อส่งมอบโซลูชั่นที่ยอดเยี่ยมซึ่งขับเคลื่อนผลลัพธ์
        </p>
        <h3>เรื่องราวความสำเร็จของลูกค้า</h3>
        <p>
          เราภาคภูมิใจในความสำเร็จของลูกค้าของเราและมีผลงานของกรณีศึกษาที่แสดงให้เห็นถึงความสามารถของเราในการส่งมอบผลลัพธ์ที่มีผลกระทบ ความมุ่งมั่นต่อความเป็นเลิศและความพึงพอใจของลูกค้าได้ทำให้เราได้รับชื่อเสียงว่าเป็นพันธมิตรที่เชื่อถือได้ในอุตสาหกรรม
        </p>
      </div>
    </section>
  );
};

export default Benefits;
