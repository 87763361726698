import React from 'react';
import webDevImage from '../images/Web Dev.webp';
import '../styles/Section.css';

const WebDevelopment = () => {
  return (
    <section className="section hero-section">
      <img src={webDevImage} alt="Web Development" className="hero-image" />
      <div className="section-content">
        <h2>บริการพัฒนาเว็บไซต์</h2>
        <h3>การพัฒนาเว็บไซต์แบบกำหนดเอง</h3>
        <p>
          บริการพัฒนาเว็บไซต์แบบกำหนดเองของเราออกแบบมาเพื่อสร้างเว็บไซต์ที่ไม่เหมือนใคร ใช้งานง่าย และสะท้อนถึงเอกลักษณ์ของแบรนด์ของคุณ เราทำงานอย่างใกล้ชิดกับลูกค้าเพื่อเข้าใจวิสัยทัศน์และวัตถุประสงค์ของพวกเขา ทำให้มั่นใจได้ว่าผลิตภัณฑ์สุดท้ายจะไม่เพียงแต่ดูดี แต่ยังมีประสิทธิภาพยอดเยี่ยมด้วย
        </p>
        <h3>โซลูชั่นอีคอมเมิร์ซ</h3>
        <p>
          Logics Forge ให้บริการโซลูชั่นอีคอมเมิร์ซที่แข็งแกร่งซึ่งช่วยให้ธุรกิจสามารถขายผลิตภัณฑ์และบริการของตนทางออนไลน์ได้อย่างราบรื่น แพลตฟอร์มอีคอมเมิร์ซของเรามีความปลอดภัย ปรับขนาดได้ และออกแบบมาเพื่อให้ประสบการณ์การช้อปปิ้งที่ราบรื่นแก่ลูกค้า
        </p>
        <h3>ระบบจัดการเนื้อหา (CMS)</h3>
        <p>
          CMS ที่มีประสิทธิภาพเป็นสิ่งสำคัญสำหรับการจัดการและอัปเดตเนื้อหาเว็บไซต์ได้อย่างมีประสิทธิภาพ เราให้บริการโซลูชั่น CMS ที่ปรับแต่งได้ตามต้องการซึ่งช่วยให้ธุรกิจสามารถรักษาเว็บไซต์ให้สดใหม่และมีเนื้อหาที่เกี่ยวข้องอยู่เสมอ
        </p>
      </div>
    </section>
  );
};

export default WebDevelopment;
