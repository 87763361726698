import React from 'react';
import ContactForm from './ContactForm';
import '../styles/Section.css';

const ContactInfo = () => {
  return (
    <section className="section content-section">
      <div className="section-content contact-info">
        <h2>ข้อมูลติดต่อ</h2>
        <p>
          พร้อมที่จะยกระดับการแสดงตนในโลกออนไลน์ของคุณแล้วหรือยัง? ติดต่อ Logics Forge วันนี้เพื่อพูดคุยเกี่ยวกับโครงการของคุณและเรียนรู้ว่าเราจะช่วยให้คุณบรรลุเป้าหมายทางธุรกิจของคุณได้อย่างไร
        </p>
      </div>
      <ContactForm />
    </section>
  );
};

export default ContactInfo;
