import React from 'react';
import Header from '../components/Header';
import WebDevelopment from '../components/WebDevelopment';
import WebDesign from '../components/WebDesign';
import MobileAppDevelopment from '../components/MobileAppDevelopment';
import OnlineMarketing from '../components/OnlineMarketing';
import SEOService from '../components/SEOService';
import Benefits from '../components/Benefits';
import ServicesSummary from '../components/ServicesSummary';
import ContactInfo from '../components/ContactInfo';
import FAQ from '../components/FAQ';
import '../styles/About.css';

const About = () => {
  return (
    <div className="about-container">
      <Header />
      <WebDevelopment />
      <WebDesign />
      <MobileAppDevelopment />
      <OnlineMarketing />
      <SEOService />
      <Benefits />
      <ServicesSummary />
      <ContactInfo />
      <FAQ />
    </div>
  );
};

export default About;
