import React from 'react';
import uxuiImage from '../images/UXUI Design.webp';
import '../styles/Section.css';

const WebDesign = () => {
  return (
    <section className="section hero-section">
      <img src={uxuiImage} alt="UX/UI Design" className="hero-image" />
      <div className="section-content">
        <h2>บริการออกแบบเว็บไซต์</h2>
        <h3>การออกแบบที่เน้นผู้ใช้เป็นศูนย์กลาง</h3>
        <p>
          ที่ Logics Forge เราให้ความสำคัญกับการออกแบบที่เน้นผู้ใช้เป็นศูนย์กลางเพื่อให้มั่นใจว่าเว็บไซต์ใช้งานง่ายและน่าสนใจ ปรัชญาการออกแบบของเรามุ่งเน้นไปที่การสร้างเว็บไซต์ที่ใช้งานง่าย ดึงดูดสายตา และได้รับการปรับให้เหมาะสมสำหรับการเปลี่ยนแปลง
        </p>
        <h3>การออกแบบที่ตอบสนองต่อทุกอุปกรณ์</h3>
        <p>
          ในยุคที่การใช้อินเทอร์เน็ตบนมือถือมีจำนวนมากกว่าคอมพิวเตอร์เดสก์ท็อป การมีการออกแบบที่ตอบสนองต่อทุกอุปกรณ์เป็นสิ่งสำคัญ บริการออกแบบที่ตอบสนองของเราช่วยให้เว็บไซต์ของคุณดูดีและทำงานได้อย่างสมบูรณ์แบบในทุกอุปกรณ์ ตั้งแต่เดสก์ท็อปไปจนถึงสมาร์ทโฟน
        </p>
        <h3>แนวปฏิบัติที่ดีที่สุดด้าน UI/UX</h3>
        <p>
          ทีมงานของเราปฏิบัติตามแนวปฏิบัติที่ดีที่สุดด้านการออกแบบ UI/UX เพื่อให้แน่ใจว่าเว็บไซต์ของคุณไม่เพียงแต่ดูดี แต่ยังให้ประสบการณ์ผู้ใช้ที่ยอดเยี่ยมอีกด้วย เรามุ่งเน้นไปที่องค์ประกอบต่างๆ เช่น เวลาในการโหลด การนำทาง และการเข้าถึงเพื่อเพิ่มความพึงพอใจของผู้ใช้
        </p>
      </div>
    </section>
  );
};

export default WebDesign;
