import React from 'react';
import '../styles/Section.css';

const ServicesSummary = () => {
  return (
    <section className="section hero-section">
      <div className="section-content">
        <h2>สรุปบริการที่มี</h2>
        <p>
          Logics Forge ให้บริการครบวงจร ได้แก่ การพัฒนาเว็บไซต์ การออกแบบ การสร้างแอปพลิเคชันมือถือ การตลาดออนไลน์ และ SEO เป้าหมายของเราคือช่วยให้ธุรกิจสร้างการแสดงตนในโลกออนไลน์ที่แข็งแกร่งและบรรลุวัตถุประสงค์ด้านการตลาดดิจิทัลของพวกเขา
        </p>
      </div>
    </section>
  );
};

export default ServicesSummary;
