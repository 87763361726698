import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/Section.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_c4dxarj',          // เปลี่ยนเป็น Service ID ของคุณ
      'template_5857y5c',         // เปลี่ยนเป็น Template ID ของคุณ
      formData,
      'tURv0v0dR9oGBvMGz'              // เปลี่ยนเป็น User ID ของคุณ
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('ฟอร์มถูกส่งแล้ว');
    }, (err) => {
      console.log('FAILED...', err);
      alert('การส่งฟอร์มล้มเหลว');
    });
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <label>
        ชื่อ:
        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
      </label>
      <label>
        นามสกุล:
        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
      </label>
      <label>
        อีเมล์:
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
      </label>
      <label>
        เบอร์โทร:
        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
      </label>
      <label>
        ข้อความ:
        <textarea name="message" value={formData.message} onChange={handleChange} required />
      </label>
      <button type="submit">ขอใบเสนอราคา</button>
    </form>
  );
};

export default ContactForm;
