import React from 'react';
import '../styles/Hero.css';
import heroImage from '../images/Hero of LOGICS FORGE.webp'; // ใช้การนำเข้ารูปภาพ

const Hero = () => {
  return (
    <section className="hero">
      <img src={heroImage} alt="Hero of LOGICS FORGE" className="hero-image" />
    </section>
  );
};

export default Hero;
