import React from 'react';
import seoImage from '../images/SEO.webp';
import '../styles/Section.css';

const SEOService = () => {
  return (
    <section className="section hero-section">
      <img src={seoImage} alt="SEO Service" className="hero-image" />
      <div className="section-content">
        <h2>บริการ SEO</h2>
        <h3>SEO บนหน้าเว็บ (On-Page SEO)</h3>
        <p>
          SEO บนหน้าเว็บเกี่ยวข้องกับการเพิ่มประสิทธิภาพเว็บเพจแต่ละหน้าเพื่อให้ได้อันดับสูงขึ้นและรับการเข้าชมที่เกี่ยวข้องมากขึ้น บริการ SEO บนหน้าเว็บของเราประกอบด้วยการเพิ่มประสิทธิภาพคีย์เวิร์ด การสร้างเมตาแท็ก และการปรับปรุงเนื้อหาเพื่อเพิ่มการมองเห็นเว็บไซต์ของคุณ
        </p>
        <h3>SEO นอกหน้าเว็บ (Off-Page SEO)</h3>
        <p>
          SEO นอกหน้าเว็บมุ่งเน้นไปที่การปรับปรุงชื่อเสียงและความน่าเชื่อถือของเว็บไซต์ของคุณผ่านการสร้างลิงก์และปัจจัยภายนอกอื่นๆ เราดำเนินกลยุทธ์เพื่อให้ได้ลิงก์ย้อนกลับคุณภาพสูงและโปรโมทเนื้อหาของคุณในหลายแพลตฟอร์ม
        </p>
        <h3>SEO ทางเทคนิค (Technical SEO)</h3>
        <p>
          SEO ทางเทคนิคทำให้มั่นใจว่าเว็บไซต์ของคุณได้รับการปรับให้เหมาะสมสำหรับการรวบรวมข้อมูลและการจัดทำดัชนีของเครื่องมือค้นหา บริการ SEO ทางเทคนิคของเราประกอบด้วยการตรวจสอบเว็บไซต์ การเพิ่มประสิทธิภาพความเร็ว และการแก้ไขปัญหาทางเทคนิคเพื่อปรับปรุงประสิทธิภาพโดยรวมของเว็บไซต์ของคุณ
        </p>
      </div>
    </section>
  );
};

export default SEOService;
