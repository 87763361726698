import React from 'react';
import digitalMarketingImage from '../images/Digital Marketing.webp';
import '../styles/Section.css';

const OnlineMarketing = () => {
  return (
    <section className="section hero-section">
      <img src={digitalMarketingImage} alt="Digital Marketing" className="hero-image" />
      <div className="section-content">
        <h2>บริการการตลาดออนไลน์</h2>
        <h3>การตลาดผ่านโซเชียลมีเดีย</h3>
        <p>
          บริการการตลาดผ่านโซเชียลมีเดียของเราช่วยให้ธุรกิจมีส่วนร่วมกับผู้ชมและสร้างการแสดงตนในโลกออนไลน์ที่แข็งแกร่ง เราสร้างและจัดการแคมเปญบนแพลตฟอร์มต่างๆ เช่น Facebook, Instagram, Twitter และ LinkedIn เพื่อเพิ่มการเข้าชมและการรับรู้แบรนด์
        </p>
        <h3>การตลาดผ่านอีเมล</h3>
        <p>
          การตลาดผ่านอีเมลเป็นเครื่องมือที่มีประสิทธิภาพในการดูแลลูกค้าเป้าหมายและรักษาความสัมพันธ์กับลูกค้า เราออกแบบและดำเนินการแคมเปญการตลาดผ่านอีเมลที่มีประสิทธิภาพซึ่งส่งเนื้อหาส่วนบุคคลให้กับผู้ชมของคุณ กระตุ้นการมีส่วนร่วมและการเปลี่ยนแปลง
        </p>
        <h3>การโฆษณาแบบจ่ายต่อคลิก (PPC)</h3>
        <p>
          การโฆษณาแบบจ่ายต่อคลิก (PPC) เป็นวิธีที่คุ้มค่าในการขับเคลื่อนการเข้าชมเป้าหมายไปยังเว็บไซต์ของคุณ บริการ PPC ของเรารวมถึงการวิจัยคีย์เวิร์ด การสร้างโฆษณา และการจัดการแคมเปญเพื่อให้แน่ใจว่าโฆษณาของคุณเข้าถึงกลุ่มเป้าหมายที่เหมาะสมและสร้างผลตอบแทนจากการลงทุนสูง
        </p>
      </div>
    </section>
  );
};

export default OnlineMarketing;
